import React, {ChangeEvent, FunctionComponent} from 'react';
import "./_index.scss"

type CheckboxProps = {
    checked?: boolean,
    defaultChecked?: boolean,
    disabled?: boolean,
    /** Text fot label */
    text?: string,
    name: string,
    value?: string,
    id?: string,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
};


export const Checkbox: FunctionComponent<CheckboxProps> = (props: CheckboxProps) => {
    let {text, id, ...attrs} = props;
    id = id || props.name;
    return (
        <div className="tim-checkbox">
            <input type="checkbox" {...attrs} id={id}/>
            <label htmlFor={id} >{text}</label>
        </div>

    );

};






