import {IDataset} from "../../types";
import {RequestStatus} from "../index";
import {
    DatasetsActionShapes,
    DatasetsActionTypes,
    datasetsSetAction, loadDatasets,
    loadDatasetsError,
    loadDatasetsPending,
    loadDatasetsSuccess
} from "./actions";
import {ApiClient} from "../../apiclient";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";

export interface IDatasetsState {
    datasets: IDataset[],
    loadStatus: null | RequestStatus,
    loadError: null | string,
    loadedAt: number | null,
}

const defaultState: IDatasetsState = {
    datasets: [],
    loadStatus: null,
    loadError: null,
    loadedAt: null,
};

export const datasetsReducer = (state: IDatasetsState = defaultState, action: DatasetsActionShapes): IDatasetsState => {

    switch (action.type) {
        case DatasetsActionTypes.LOAD_DATASETS_SUCCESS:
            return {
                ...defaultState,
                datasets: action.payload,
                loadStatus: RequestStatus.SUCCESS,
                loadedAt: Date.now(),
            };

        case DatasetsActionTypes.LOAD_DATASETS_PENDING:
            return {
                ...defaultState,
                loadStatus: RequestStatus.PENDING
            };

        case DatasetsActionTypes.LOAD_DATASETS_ERROR:
            return {
                ...defaultState,
                loadError: action.payload
            };

        case DatasetsActionTypes.DATASETS_APPEND_NEW:
            return {
                ...state,
                datasets: [...state.datasets, action.payload]
            };

        case DatasetsActionTypes.SET_DATASETS:
            return {
                ...state,
                datasets: action.payload,
            };

        default:
            return state;

    }

};

export const datasetsMiddlewareFactory = (client: ApiClient): Middleware => {
    return (store: MiddlewareAPI) => (next: Dispatch) => (action: DatasetsActionShapes) => {
        if(action.type === DatasetsActionTypes.LOAD_DATASETS) {
            store.dispatch(loadDatasetsPending());
            return client.dataset.getAll().then(datasets => {
                store.dispatch(loadDatasetsSuccess(datasets));
            }).catch(err => {
                store.dispatch(loadDatasetsError(err.message));
            });
        }

        if(action.type === DatasetsActionTypes.DATASETS_DELETE) {
            return client.dataset.delete(action.payload)
                .then(() => {
                    let d = store.getState()
                        .datasets
                        .datasets.filter((e: IDataset) => e.id !== action.payload);
                    store.dispatch(datasetsSetAction(d));
                })
                .catch(err => {
                    // @TODO: Show notification
                });
        }

        if(action.type === DatasetsActionTypes.UPDATE_CSV_FILE) {
            return client.dataset.update(
                action.payload.id,
                action.payload.workspace,
                action.payload.file)
                .then(() => {
                    // @TODO Update state from response instead of reload
                    store.dispatch(loadDatasets());
            }).catch(err => {
                // @TODO Show notification
                console.log(err);
            });
        }

        next(action);
    }
};