import React, {FunctionComponent} from 'react';
import "./_index.scss";
import {IWorkspace} from "../../../types";
import {Link} from "react-router-dom";

type WorkspaceListProps = {
    items: IWorkspace[],
    title: string,
};

export const WorkspaceList: FunctionComponent<WorkspaceListProps> = (props) => {
    const formatDate = (str: string) => {
        let d = new Date(str);
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    };

    return (
        <div className="tim-workspacelist">
            <h3>{props.title}</h3>
            <div className="tim-workspacelist-items">
                {props.items.map(e => {
                    return (
                        <div className="tim-workspacelist-item" key={e.id}>
                            <div className="tim-workspacelist-item__title">
                                <Link to={"/workspaces/" + e.id} >{e.name}</Link>
                            </div>
                            <div className="tim-workspacelist-item__actions">
                                <div className="tim-workspacelist-item__date">
                                    {formatDate(e.datetime_modified)}
                                </div>
                                <div className="tim-workspacelist-item__icons">
                                    <span className="tim-icon"></span>
                                    <span className="tim-icon"></span>
                                    <span className="tim-icon"></span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

        </div>
    );

};