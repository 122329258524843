import React, {FunctionComponent} from 'react';
import "./_index.scss";
import datasets from "../../../assets/icons/datasets-icon.jpg";
import workspaces from "../../../assets/icons/workspaces-icon.jpg";
import forecasting from "../../../assets/icons/forecasting-icon.jpg";
import models from "../../../assets/icons/models-icons.jpg";
import experiments from "../../../assets/icons/experiments-icon.jpg";
import {Link} from "react-router-dom";

export const Sidebar: FunctionComponent = (props) => {
    return(
        <div className="tim-sidebar">
            {props.children}
        </div>
    );
};

const icons = {
    datasets,
    workspaces,
    forecasting,
    models,
    experiments,
};

type SidebarItemProps = {
    icon: "datasets" | "workspaces" | "forecasting" | "models" | "experiments",
    text: string,
    active?: boolean,
};

export const SidebarItem: FunctionComponent<SidebarItemProps> = (props: SidebarItemProps) => {
    let active = props.active || false;
    let icon = icons[props.icon];

    return(
        <div className={active ? "tim-sidebar-item tim-sidebar-item__active" : "tim-sidebar-item"}>
            <Link to="/">
                <img src={icon} className="tim-sidebar-item__icon"/>
                <div className="tim-sidebar-item__text">{props.text}</div>
            </Link>
        </div>
    )

};