import {IDataset} from "../../types";

export enum DatasetsActionTypes {
    LOAD_DATASETS = "LOAD_DATASETS",
    LOAD_DATASETS_SUCCESS = "LOAD_DATASETS_SUCCESS",
    LOAD_DATASETS_PENDING = "LOAD_DATASETS_PENDING",
    LOAD_DATASETS_ERROR = "LOAD_DATASETS_ERROR",
    DATASETS_APPEND_NEW = "DATASETS_APPEND_NEW",
    DATASETS_DELETE = "DATASETS_DELETE",
    SET_DATASETS = "DATASETS_SET_DATASETS",
    UPDATE_CSV_FILE = "DATASETS_UPDATE_CSV_FILE",
    
}

export type loadDatasetsShape = {
    type: DatasetsActionTypes.LOAD_DATASETS
};

export const loadDatasets = () => {
    return {
        type: DatasetsActionTypes.LOAD_DATASETS
    }
};

export type loadDatasetsSuccessShape = {
    type: DatasetsActionTypes.LOAD_DATASETS_SUCCESS,
    payload: IDataset[]
}

export const loadDatasetsSuccess = (datasets: IDataset[]) => {
    return {
        type: DatasetsActionTypes.LOAD_DATASETS_SUCCESS,
        payload: datasets
    }
};


export type loadDatasetsPendingShape = {
    type: DatasetsActionTypes.LOAD_DATASETS_PENDING
};

export const loadDatasetsPending = () => {
    return {
        type: DatasetsActionTypes.LOAD_DATASETS_PENDING
    }
};


export type loadDatasetsErrorShape = {
    type: DatasetsActionTypes.LOAD_DATASETS_ERROR,
    payload: string
};

export const loadDatasetsError = (err: string) => {
    return {
        type: DatasetsActionTypes.LOAD_DATASETS_ERROR,
        payload: err
    }
};

type datasetsAppendActionShape = {
    type: DatasetsActionTypes.DATASETS_APPEND_NEW,
    payload: IDataset
};

export const datasetsAppendAction = (dataset: IDataset) => {
    return {
        type: DatasetsActionTypes.DATASETS_APPEND_NEW,
        payload: dataset
    };
};

type datasetsDeleteActionShape = {
    type: DatasetsActionTypes.DATASETS_DELETE,
    payload: number
};

export const datasetsDeleteAction = (id: number) => {
    return {
        type: DatasetsActionTypes.DATASETS_DELETE,
        payload: id,
    }
};

type datasetsSetActionShape = {
    type: DatasetsActionTypes.SET_DATASETS,
    payload: IDataset[],
};

export const datasetsSetAction = (datasets: IDataset[]) => {
    return {
        type: DatasetsActionTypes.SET_DATASETS,
        payload: datasets
    }
};

type datasetsUpdateFileShape = {
    type: DatasetsActionTypes.UPDATE_CSV_FILE,
    payload: {
        id: number,
        workspace: number,
        file: File
    }
};

export const datasetsUpdateFile = (id: number, workspace: number, file: File): datasetsUpdateFileShape => {
    return {
        type: DatasetsActionTypes.UPDATE_CSV_FILE,
        payload: {
            id,
            workspace,
            file
        }
    }
};


export type DatasetsActionShapes = loadDatasetsShape |
    loadDatasetsSuccessShape |
    loadDatasetsPendingShape |
    loadDatasetsErrorShape |
    datasetsAppendActionShape |
    datasetsDeleteActionShape |
    datasetsSetActionShape |
    datasetsUpdateFileShape;