import {DatasetSourceType} from "../../types";
import {RequestStatus} from "../index";
import {
    addDatasetActionCreateCsvShape,
    addDatasetActionError,
    AddDatasetActionShapes,
    AddDatasetActionTypes
} from "./actions";
import {ApiClient} from "../../apiclient";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {datasetsAppendAction} from "../datasets/actions";


export interface IAddDatasetState {
    name: string;
    "source_type": DatasetSourceType;
    hostname: string;
    username: string;
    password: string;
    status: null | RequestStatus;
    error: string;
}

const defaultState: IAddDatasetState = {
    name: "",
    "source_type": DatasetSourceType.CSV,
    hostname: "",
    username: "",
    password: "",
    status: null,
    error: ""
};

export const addDatasetReducer = (state: IAddDatasetState = defaultState, action: AddDatasetActionShapes): IAddDatasetState => {
    switch (action.type) {
        case AddDatasetActionTypes.SET_NAME:
            return {
                ...state,
                status: null,
                error: "",
                name: action.payload
            };

        case AddDatasetActionTypes.SET_HOSTNAME:
            return {
                ...state,
                status: null,
                error: "",
                hostname: action.payload
            };

        case AddDatasetActionTypes.SET_USERNAME:
            return {
                ...state,
                status: null,
                error: "",
                username: action.payload
            };

        case AddDatasetActionTypes.SET_PASSWORD:
            return {
                ...state,
                status: null,
                error: "",
                password: action.payload
            };

        case AddDatasetActionTypes.SET_SOURCE_TYPE:
            return {
                ...state,
                status: null,
                error: "",
                source_type: <DatasetSourceType>action.payload
            };
        case AddDatasetActionTypes.SET_PENDING_STATUS:
            return {
                ...state,
                status: RequestStatus.PENDING,
                error: ""
            };

        case AddDatasetActionTypes.SET_SUCCESS_STATUS:
            return {
                ...defaultState,
                status: RequestStatus.SUCCESS
            };

        case AddDatasetActionTypes.SET_ERROR_STATUS:
            return {
                ...state,
                status: RequestStatus.ERROR,
                error: action.payload
            };
        case AddDatasetActionTypes.RESET_STATE:
            return {
                ...defaultState
            };
    }

    return state;
};

export const addDatasetMiddlewareFactory = (client: ApiClient): Middleware  => {
    return (store: MiddlewareAPI) => (next: Dispatch) => (action: AddDatasetActionShapes) => {
        if(action.type === AddDatasetActionTypes.CREATE_DATASET_CSV) {
            store.dispatch({type: AddDatasetActionTypes.SET_PENDING_STATUS});
            return client.dataset.createCsvDataset(
                (<addDatasetActionCreateCsvShape>action).payload.workspace,
                (<addDatasetActionCreateCsvShape>action).payload.name,
                (<addDatasetActionCreateCsvShape>action).payload.file,
            ).then(dataset => {
                store.dispatch({type: AddDatasetActionTypes.SET_SUCCESS_STATUS});
                store.dispatch(datasetsAppendAction(dataset));
                return dataset;
            }).catch(err => {
                store.dispatch(addDatasetActionError(err.message));
            });
        }

        next(action);
    };
};