import React, {FunctionComponent} from 'react';
import {IAppState, RequestStatus} from "../../../store";
import {Dispatch} from "redux";
import {datasetsDeleteAction, datasetsUpdateFile, loadDatasets} from "../../../store/datasets/actions";
import {RouteComponentProps} from "react-router";
import {connect} from "react-redux";
import {DatasetsTable} from "../../elements/datasetstable";
import {DatasetForm} from "../../elements/datasetform";
import {datasetActions} from "../../../store/dataset/actions";

const mapStateToProps = (state: IAppState) => {
    return {
        datasets: state.datasets.datasets,
        loadStatus: state.datasets.loadStatus,
        loadError: state.datasets.loadError,
        expanded: state.dataset,

    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadDatasets: () => dispatch(loadDatasets()),
        deleteDataset: (id: number) => dispatch(datasetsDeleteAction(id)),
        updateFile: (id: number, workspace: number, file: File) => dispatch(datasetsUpdateFile(id, workspace, file)),
        loadExpanded: (id: number) => dispatch(datasetActions.load(id)),
        clearExpanded: () => dispatch(datasetActions.clear()),

    }
};

type RouteParams = {
    id: string
}

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> &
    RouteComponentProps<RouteParams>

const WorkspaceComponent: FunctionComponent<Props> = (props) => {
    const id = Number(props.match.params.id);
    const loaded = props.loadStatus === RequestStatus.SUCCESS;
    if(props.loadStatus === null) {
        props.loadDatasets();
    }
    return (
        <div className="container" >
            <div className="colums">
                <div className="column is-12">
                    <div className="tim-datasetformwrapper">
                        <DatasetForm workspace={id}/>
                    </div>

                    <DatasetsTable
                        datasets={props.datasets.filter(e => e.workspace === id)}
                        loaded={loaded}
                        deleteDataset={props.deleteDataset}
                        updateFile={props.updateFile}
                        expanded={props.expanded}
                        loadExpanded={props.loadExpanded}
                        clearExpanded={props.clearExpanded}
                    />
                </div>
            </div>
        </div>
    );
};

export const Workspace = connect(mapStateToProps, mapDispatchToProps)(WorkspaceComponent);