import {AxiosInstance, AxiosPromise} from "axios";
import {DatasetSourceType, IDataset, IUser, IWorkspace} from "../types";

export class ApiClient {

    protected token: string = "";
    public user: User;
    public workspace: Workspace;
    public dataset: Dataset;


    constructor(public axios: AxiosInstance, protected baseUrl: string) {
        this.user = new User(this);
        this.workspace = new Workspace(this);
        this.dataset = new Dataset(this);
    }

    setToken(token: string): ApiClient {
        this.token = token;
        return this;
    }

    getToken() {
        return this.token;
    }

    makeUrl(url: string) {
        return this.baseUrl + url;
    }

    getRequestConfig() {
        if(!this.token) {
            throw new Error("Missing JWT token");
        }

        return {
            headers: {
                Authorization: "Bearer " + this.token
            }
        }
    }

}

class User {
    constructor(protected client: ApiClient) {}

    login(email: string, password: string): Promise<IUser> {
        let url = this.client.makeUrl("/api/auth/");
        return this.client.axios.post<IUser>(url, {
            username: email,
            password: password
        }).then(res => res.data);
    }
    getUser(id: number): Promise<IUser> {
        const url = this.client.makeUrl("/api/users/" + id + "/");
        const config = this.client.getRequestConfig();
        return this.client.axios.get(url, config).then(res => {
            return {
                user_id: res.data.id,
                token: this.client.getToken(),
                email: res.data.email,
                full_name: res.data.first_name + " " + res.data.last_name,
                username: res.data.email
            }
        });
    }
}

class Workspace {
    constructor(protected client: ApiClient) {}

    getAll(): Promise<IWorkspace[]> {
        const url = this.client.makeUrl("/api/workspaces/");
        const config = this.client.getRequestConfig();
        return this.client.axios.get<IWorkspace[]>(url, config).then(res => res.data);
    }

    create(name: string, ws_type: number): Promise<IWorkspace> {
        const url = this.client.makeUrl("/api/workspaces/");
        const config = this.client.getRequestConfig();
        return this.client.axios
            .post<IWorkspace>(url, {name, ws_type, description: "..."}, config).then(res => res.data);
    }
}

class Dataset {
    constructor(protected client: ApiClient) {}

    getAll(): Promise<IDataset[]> {
        const url = this.client.makeUrl("/api/datasets/");
        const config = this.client.getRequestConfig();
        return this.client.axios.get<IDataset[]>(url, config).then(res => res.data);
    }

    createCsvDataset(workspace: number, name: string, file: File): Promise<IDataset> {
        const url = this.client.makeUrl("/api/datasets/");
        const config = this.client.getRequestConfig();
        config.headers["Content-Type"] = "multipart/form-data";

        const data = new FormData();
        data.append("name", name);
        data.append("sampling_rate", "4");
        data.append("workspace", String(workspace));
        data.append("source_type", String(DatasetSourceType.CSV));
        data.append("csv", file);

        return this.client.axios.post<IDataset>(url, data, config).then(res => res.data);
    };

    delete(id: number): AxiosPromise {
        const url = this.client.makeUrl("/api/datasets/" + id + "/");
        const config = this.client.getRequestConfig();
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        return this.client.axios.delete(url, config);
    }

    update(id: number, workspace: number, file: File): AxiosPromise {
        const url = this.client.makeUrl("/api/datasets/" + id + "/");
        const config = this.client.getRequestConfig();
        config.headers["Content-Type"] = "multipart/form-data";

        const data = new FormData();
        data.append("csv", file);
        data.append("workspace", String(workspace));

        return this.client.axios.patch(url, data, config);
    };

    get(id: number): Promise<IDataset> {
        const url = this.client.makeUrl("/api/datasets/" + id + "/");
        const config = this.client.getRequestConfig();
        return this.client.axios.get<IDataset>(url, config).then(res => res.data);
    }
}