import React, {FunctionComponent} from 'react';
import {IDatasetState} from "../../../store/dataset";
import {RequestStatus} from "../../../store";
import {ChartOptions} from "billboard.js";
import BillboardChart from "react-billboardjs";
import "react-billboardjs/lib/billboard.css";

type Props = {
    expanded: IDatasetState
}

export const DatasetTableChart: FunctionComponent<Props> = (props) => {

    if(props.expanded.status === RequestStatus.PENDING) {
        return <div>Loading data</div>;
    }

    if(props.expanded.status === RequestStatus.ERROR) {
        return <div className="text-danger">ERROR: {props.expanded.error}</div>
    }

    if(!props.expanded.dataset || !props.expanded.dataset.values || props.expanded.dataset.values.length === 0) {
        return <div>No data</div>
    }

    const config = {
        data: {
            columns: props.expanded.dataset.source_headers.map(e => [e]),
            "x": "timestamp",
        },
        axis: {
            x: {
                type: "timeseries",
                tick: {
                    format: "%Y-%m-%d %H:%M:%S"
                }
            }
        }
    };
    let data = {};
    //config.data[props.expanded.dataset.source_headers[0]] = "x";
    props.expanded.dataset.values.forEach(row => {
        row.forEach((e, i) => {
            if(i === 0) {
                // @ts-ignore
                config.data.columns[i].push(new Date(e));
                return;
            }

            // @ts-ignore
            config.data.columns[i].push(e);
        });
    });

    const zoom = {
        enabled: {
            type: "drag"
        }
    };


        console.log(config);



    return <BillboardChart data={config.data} axis={config.axis} zoom={zoom}/>
};