export const APP_NAME = "TIM Studio";
export const BASE_URL = "http://timstudio365-devel.westeurope.cloudapp.azure.com";
export const STORAGE_KEY = "tim_user";

export interface IUser {
    "token": string;
    "user_id": number;
    "username": string;
    "email": string;
    "full_name": string;
}

export interface IWorkspace {
    "id": number,
    "userfavorite_set": number[];
    "datetime_created": string;
    "datetime_modified": string;
    "name": string;
    "ws_type": IWorspaceTypes,
    "description": string;
}

export enum IWorspaceTypes {
    FORECASTING = 0,
    ANOMALY = 1,
}

export enum DatasetSourceType {
    CSV = 0,
    SQL = 1,
}

export interface IDataset {
    "id": number;
    "name": string;
    "sampling_rate": number;
    "workspace": number;
    "source_headers": string[];
    "values": Array<string|number>[];
    "datetime_modified": string;
    "rows_number": number;
    /** Date created */
    "target_sample_from": string;
    "source_type": DatasetSourceType;
}