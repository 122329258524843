import {applyMiddleware, combineReducers, createStore} from "redux";
import {userMiddlewareFactiory, userReducer} from "./user";
import { composeWithDevTools } from 'redux-devtools-extension';
import {ApiClient} from "../apiclient";
import {workspacesMiddlewareFactory, workspacesReducer} from "./workspaces";
import {datasetsMiddlewareFactory, datasetsReducer} from "./datasets";
import {addDatasetMiddlewareFactory, addDatasetReducer} from "./adddataset";
import {datasetMiddlewareFactiory, datasetReducer} from "./dataset";

export enum RequestStatus {
    PENDING = 1,
    SUCCESS = 2,
    ERROR = 3,
}

const rootReducer = combineReducers({
    user: userReducer,
    workspaces: workspacesReducer,
    datasets: datasetsReducer,
    addDataset: addDatasetReducer,
    dataset: datasetReducer,
});

export type IAppState = ReturnType<typeof rootReducer>;

export const configureStore = (client: ApiClient) => {

    return createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(
                userMiddlewareFactiory(client),
                workspacesMiddlewareFactory(client),
                datasetsMiddlewareFactory(client),
                addDatasetMiddlewareFactory(client),
                datasetMiddlewareFactiory(client),
            )
        )
    );
};