import React, {ChangeEvent, Component, FunctionComponent} from 'react';
import "./_index.scss";

type RadioButtonProps = {
    checked?: boolean,
    defaultChecked?: boolean,
    label: string,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    id: string,
    name: string,
    value?: string
};

export const RadioButton: FunctionComponent<RadioButtonProps> = (props) => {
    const {label, ...attr} = props;

    return(
        <div className="tim-radiobutton">
            <input type="radio" {...attr}/>

            <label htmlFor={props.id}><div className="tim-radiobutton__check" />{label}</label>
        </div>
    );
};