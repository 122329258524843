import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import {ApiClient} from "./apiclient";
import {BASE_URL, IUser, STORAGE_KEY} from "./types";
import {configureStore} from "./store";
import {BrowserRouter, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {App} from "./App";
import {refreshUserAction, setUserAction} from "./store/user/actions";

const client = new ApiClient(axios.create(), BASE_URL);
const store = configureStore(client);
const userData = localStorage.getItem(STORAGE_KEY);
let user: IUser;
if(userData) {
    try {
        user = JSON.parse(userData);
        client.setToken(user.token);
        store.dispatch(setUserAction(user));
        store.dispatch(refreshUserAction(user.user_id));
    } catch (e) {

    }
}


ReactDOM.render((
    <BrowserRouter>
        <Provider store={store}>
                <App/>
        </Provider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
