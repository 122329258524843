import {
    addWorkspaceAction,
    loadWorkspacesError,
    loadWorkspacesPending,
    loadWorkspacesSuccess,
    setCreateWorkspaceError,
    WorkspacesActionShapes,
    WorkspacesActionTypes
} from "./actions";
import {RequestStatus} from "../index";
import {IWorkspace} from "../../types";
import {ApiClient} from "../../apiclient";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";


export interface IWorkspacesState {
    workspaces: IWorkspace[],
    loadStatus: null | RequestStatus,
    loadError: string | null,
    loadedAt: number | null,
    search: string,
    createError: string,
}

const defaultState: IWorkspacesState = {
    workspaces: [],
    loadStatus: null,
    loadError: null,
    loadedAt: null,
    search: "",
    createError: "",
};

export const workspacesReducer = (state: IWorkspacesState = defaultState, action: WorkspacesActionShapes) => {
    switch (action.type) {
        case WorkspacesActionTypes.LOAD_WORKSPACES_PENDING:
            return {
                ...defaultState,
                loadStatus: RequestStatus.PENDING
            };

        case WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS:
            return {
                ...state,
                workspaces: action.payload,
                loadStatus: RequestStatus.SUCCESS,
                loadedAt: Date.now(),
                loadError: null,
            };

        case WorkspacesActionTypes.LOAD_WORKSPACES_ERROR:
            return {
                ...defaultState,
                loadError: action.payload,
                loadStatus: RequestStatus.ERROR
            };

        case WorkspacesActionTypes.UPDATE_WORKSPACES_SEARCH:
            return {
                ...state,
                search: action.payload
            };

        case WorkspacesActionTypes.SET_CREATE_ERROR:
            return {
                ...state,
                createError: action.payload
            };

        case WorkspacesActionTypes.ADD_WORKSPACE:
            return {
                ...state,
                workspaces: [...state.workspaces, action.payload]
            };

        default:
            return state;
    }
};

export const workspacesMiddlewareFactory = (client: ApiClient): Middleware => {

    return (store: MiddlewareAPI) => (next: Dispatch) => (action: WorkspacesActionShapes) => {
        if(action.type === WorkspacesActionTypes.LOAD_WORKSPACES) {
            store.dispatch(loadWorkspacesPending());
            return client.workspace.getAll().then(workspaces => {
                store.dispatch(loadWorkspacesSuccess(workspaces));
                return workspaces;
            }).catch(err => {
                store.dispatch(loadWorkspacesError(err.message));
            });
        }

        if(action.type === WorkspacesActionTypes.CREATE_WORKSPACE) {
            return client.workspace.create(action.payload.name, action.payload.ws_type)
                .then(workspace => {
                    store.dispatch(setCreateWorkspaceError(""));
                    store.dispatch(addWorkspaceAction(workspace));

                }).catch(err => {
                    console.log(err);
                    store.dispatch(setCreateWorkspaceError(err.message));
                });
        }

        return next(action);
    }
};