import {ApiClient} from "../../apiclient";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";
import {
    loginErrorAction,
    logoutAction,
    setUserAction,
    UserActionShapes,
    UserActionTypes,
    userLoginPending
} from "./actions";
import {STORAGE_KEY} from "../../types";
import {IAppState} from "../index";

export interface IUserLoginData {
    email: string,
    password: string,
    remember: boolean
}

export enum UserAuthState {
    NOAUTH = 1,
    PENDING = 2,
    AUTH = 3,
}

export type UserStateType = {
    token: string | null,
    user_id?: number,
    email: string | null,
    full_name: string | null,
    language: string | null,
    loginError: string | null,
    authState: UserAuthState
};

const defaultState: UserStateType = {
    token: null,
    email: null,
    full_name: null,
    language: "en",
    loginError: null,
    authState: UserAuthState.NOAUTH,
};


export const userReducer = (state: UserStateType = defaultState, action: UserActionShapes): UserStateType => {

    switch (action.type) {

        case UserActionTypes.LOGIN_ERROR:
            return {
                ...state,
                authState: UserAuthState.NOAUTH,
                loginError: action.payload
            };
        case UserActionTypes.LOGOUT:
            return {
                ...defaultState
            };
        case UserActionTypes.LOGIN_PENDING:
            return {
                ...state,
                authState: UserAuthState.PENDING,
                loginError: null,
            };
        case UserActionTypes.SET_USER_DATA:
            return {
                ...state,
                token: action.payload.token,
                email: action.payload.email,
                full_name: action.payload.full_name,
                user_id: action.payload.user_id,
                loginError: null,
                authState: UserAuthState.AUTH,
            };
        default:
            return state;
    }
};

export const userMiddlewareFactiory = (client: ApiClient): Middleware => {

    return (store: MiddlewareAPI<Dispatch, IAppState>) => (next: Dispatch) => (action: UserActionShapes) => {

        if(action.type === UserActionTypes.LOGIN) {
            store.dispatch(userLoginPending());
            return client.user.login(action.payload.email, action.payload.password)
                .then(user => {
                    client.setToken(user.token);
                    store.dispatch(setUserAction(user));
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(user));
                    return user;
                }).catch(err => {
                   store.dispatch(loginErrorAction(err.message));
                });
        }

        if(action.type === UserActionTypes.REFRESH_USER) {

            return client.user.getUser(action.payload)
                .then(user => {
                    store.dispatch(setUserAction(user));
                }).catch(err => {
                    store.dispatch(logoutAction());
                });
        }

        if(action.type === UserActionTypes.LOGOUT) {
            localStorage.removeItem(STORAGE_KEY);
        }

        return next(action);
    };
};


