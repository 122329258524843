import {IDataset} from "../../types";

export enum DatasetActionTypes {
    LOAD = "LOAD_DATASET_DETAIL",
    PENDING = "LOAD_DATASET_DETAIL_PENDING",
    SUCCESS = "LOAD_DATASET_DETAIL_SUCESS",
    ERROR = "LOAD_DATASET_DETAIL_ERROR",
    CLEAR = "DATASET_DETAIL_CLEAR",
}


type loadDatasetActionShape = {
    type: DatasetActionTypes.LOAD,
    payload: number,
};

const load = (id: number): loadDatasetActionShape => {
    return {
        type: DatasetActionTypes.LOAD,
        payload: id,
    };
};

type pendingDatasetActionShape = {
    type: DatasetActionTypes.PENDING,
    payload: number,
};

const pending = (id: number): pendingDatasetActionShape => {
    return {
        type: DatasetActionTypes.PENDING,
        payload: id,
    };
};

type successDatasetActionShape = {
    type: DatasetActionTypes.SUCCESS,
    payload: IDataset,
};

const success = (dataset: IDataset): successDatasetActionShape => {
    return {
        type: DatasetActionTypes.SUCCESS,
        payload: dataset,
    };
};

type failDatasetActionShape = {
    type: DatasetActionTypes.ERROR,
    payload: string,
};

const fail = (err: string): failDatasetActionShape => {
    return {
        type: DatasetActionTypes.ERROR,
        payload: err,
    }
};

type clearDatasetActionShape = {
    type: DatasetActionTypes.CLEAR
};

const clear = () => {
    return {
        type: DatasetActionTypes.CLEAR
    };
};

export const datasetActions = {
    load,
    pending,
    success,
    fail,
    clear,
};


export type DatasetActionShapes = loadDatasetActionShape |
    pendingDatasetActionShape |
    successDatasetActionShape |
    failDatasetActionShape |
    clearDatasetActionShape;

