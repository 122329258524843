import React, {ChangeEvent, Component, FunctionComponent, useState} from 'react';
import {IDataset} from "../../../types";
import "./_index.scss";
import {stringToDateTime} from "../../../helpers";
import {IDatasetState} from "../../../store/dataset";
import {DatasetTableChart} from "../datasettablechart";

type Props = {
    datasets: IDataset[],
    loaded: boolean,
    deleteDataset: (id: number) => void,
    updateFile: (id: number, workspace: number, file: File) => void,
    expanded: IDatasetState,
    loadExpanded: (id: number) => void,
    clearExpanded: () => void,
};

type HeaderProps = {
    label: string,
    sorted: boolean,
    sortable: boolean,
    clicked: () => undefined
};

const TableHeader: FunctionComponent<HeaderProps> = (props) => {
    return(
        <th
            className={props.sortable? "tim-datasettable__sortable" : ""}
            onClick={() => props.clicked()}>
            {props.label}
            {props.sortable && <span className="tim-icon"></span> }
        </th>
    )
};


type UploadButtonProps = {
    id: number,
    workspace: number,
    datasetsUpdateFile: (id: number, workspace: number, file: File) => void,
};

const UploadButton: FunctionComponent<UploadButtonProps> = (props) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files) {
            props.datasetsUpdateFile(props.id, props.workspace, e.target.files[0])
        }
    };

    const elemId = `datasetfileupload_${props.id}`;

    return (
        <div className="tim-datasettable__uploadbutton">
            <label htmlFor={elemId}>
                <span
                    className="tim-icon tim-datasettable__uploadicon"
                    title="Upload new file to dataset"
                ></span>
                <input type="file" onChange={handleChange} id={elemId} />
            </label>
        </div>

    );
};


export const DatasetsTable: FunctionComponent<Props> = (props) => {
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("ID");

    if(!props.loaded) {
        return <div>Loading datasets</div>;
    }

    const handleExpandClick = (id: number) => {
        if(id !== props.expanded.id) {
            props.loadExpanded(id);
        } else {
            props.clearExpanded();
        }
    };

    let datasets = props.datasets;
    if(search) {
        datasets = datasets.filter(e => e.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }

    switch (sort) {
        case "ID":
            datasets = datasets.sort((a, b) => b.id - a.id);
            break;
        case "Name":
            datasets = datasets.sort((a, b) => a.name < b.name ? -1 : 1);
            console.log("sorted");
            break;
        case "Records":
            datasets = datasets.sort((a, b) => a.rows_number - b.rows_number);
            break;
        case "Last Updated":
            datasets = datasets.sort((a, b) => a.datetime_modified < b.datetime_modified ? 1 : -1);
            break;
        case "Target Sample From":
            datasets = datasets.sort((a, b) => a.target_sample_from < b.target_sample_from ? 1 : -1);
            break;
    }

    return (
        <div className="tim-datasettable__wrapper">
            <div className="tim-datasettable__headerwrapper">
                <span className="tim-datasettable__title">Datasets</span>
                <div className="tim-datasettable__searchwrapper">
                    <input
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <span className="tim-icon"></span>
                </div>
            </div>
            <table className="table tim-datasettable">
                <thead>
                <tr>
                    <TableHeader
                        label="View"
                        sorted={false}
                        sortable={false}
                        clicked={() =>  undefined}
                    />
                    <TableHeader
                        label="Name"
                        sorted={sort === "Name"}
                        sortable={true}
                        clicked={() => {setSort("Name"); return undefined}}
                    />
                    <TableHeader
                        label="ID"
                        sorted={sort === "ID"}
                        sortable={true}
                        clicked={() => {setSort("ID"); return undefined}}
                    />
                    <TableHeader
                        label="Predictors"
                        sorted={sort === "Predictors"}
                        sortable={false}
                        clicked={() =>  undefined}
                    />

                    <TableHeader
                        label="Records"
                        sorted={sort === "Records"}
                        sortable={true}
                        clicked={() => {setSort("Records"); return undefined}}
                    />

                    <TableHeader
                        label="Target"
                        sorted={sort === "Target"}
                        sortable={false}
                        clicked={() =>  undefined}
                    />

                    <TableHeader
                        label="Last Updated"
                        sorted={sort === "Last Updated"}
                        sortable={true}
                        clicked={() => {setSort("Last Updated"); return undefined}}
                    />

                    <TableHeader
                        label="Target Sample From"
                        sorted={sort === "Target Sample From"}
                        sortable={true}
                        clicked={() => {setSort("Target Sample From"); return undefined}}
                    />

                    <TableHeader
                        label="Update"
                        sorted={sort === "Update"}
                        sortable={false}
                        clicked={() =>  undefined}
                    />
                </tr>
                </thead>
                <tbody>
                {datasets.map(e => {
                    const expanded = e.id === props.expanded.id;
                    return (
                        <>
                        <tr key={e.id} className={expanded ? "tim-datasettable__rowexpanded" : ""}>
                            <td className="tim-datasettable__toggleexpand">
                                <span
                                    onClick={ev => handleExpandClick(e.id)}
                                    className="tim-icon">{expanded ? "" : ""}</span></td>
                            <td>{e.name}</td>
                            <td>{e.id}</td>
                            <td>{e.source_headers.length - 2}</td>
                            <td>{e.rows_number}</td>
                            <td>{e.source_headers[1]}</td>
                            <td>{stringToDateTime(e.datetime_modified)}</td>
                            <td>{stringToDateTime(e.target_sample_from)}</td>
                            <td>
                                <UploadButton id={e.id} workspace={e.workspace} datasetsUpdateFile={props.updateFile}/>
                                <span
                                    onClick={ev => props.deleteDataset(e.id)}
                                    className="tim-icon tim-datasettable__deleteicon"
                                ></span>
                            </td>
                        </tr>
                    {expanded && (<tr><td colSpan={9}><DatasetTableChart expanded={props.expanded}/></td></tr>)}
                    </>
                    )
                })}
                </tbody>
            </table>
        </div>

    );

};