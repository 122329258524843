import React, {FunctionComponent} from 'react';
import "./_index.scss";
import {connect} from "react-redux";
import logo from "../../../assets/login-logo.png";
import authorLogo from "../../../assets/login-author-logo.png";
import {LoginForm, LoginFormSubmitData} from "../../elements/loginform";
import {IAppState} from "../../../store";
import {Dispatch} from "redux";
import {Redirect} from "react-router";
import {UserAuthState} from "../../../store/user";
import {userLoginAction} from "../../../store/user/actions";

const mapStateToProps = (state: IAppState) => {
    return {
        loginError: state.user.loginError,
        authState: state.user.authState,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        login: (email: string, pass: string, remember: boolean) => dispatch(userLoginAction(email, pass, remember))
    };
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LoginPageComponent: FunctionComponent<Props> =  (props: Props) => {
    console.log(props);
    if(props.authState === UserAuthState.AUTH) {
        return <Redirect to="/" />;
    }

    const onSubmit = (data: LoginFormSubmitData) => {
        props.login(data.username, data.password, data.remember);
    };

    let errMessage: string = props.loginError || "";

    return(
        <div id="loginpage">
            <div className="loginbox">
                <div className="loginbox-header">
                    <img src={logo} width="187" height="123"/>
                    <div className="loginbox-header__version">
                        Version 0.3
                    </div>

                </div>
                <div className="loginbox-content">
                    <h3>Please login to your account</h3>
                    <LoginForm
                        inRequest={props.authState === UserAuthState.PENDING}
                        onSubmit={onSubmit}
                        errMessage={errMessage}
                    />

                </div>
                <div className="loginbox-footer">
                    <div className="loginbox-footer__terms">
                        Term of use. Privacy policy
                    </div>
                    <img src={authorLogo} />
                </div>
            </div>

        </div>
    );
};

export const LoginPage = connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent);