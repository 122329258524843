import React, {ChangeEvent, Component, FunctionComponent} from 'react';
import "./_index.scss";

type TextBoxProps = {
    placeholder?: string,
    id?: string,
    value?: string,
    name?: string,
    defaultValue?: string,
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
    type?: "text" | "password",
    wrapped?: boolean,
};

export const TextBox: FunctionComponent<TextBoxProps> = (props: TextBoxProps) => {
    let {type, wrapped, ...attr} = props;
    type = type || "text";
    const input = <input className="tim-textbox" {...attr} type={type} />;

    if(!wrapped) {
        return  input;
    }

    return (
        <div className="field">
            <div className="control">
                {input}
            </div>
        </div>
    );
};