import React, {Component, FunctionComponent} from 'react';
import {IAppState} from "./store";
import {UserAuthState} from "./store/user";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {LoginPage} from "./components/pages/login";
import {PrivateRoute} from "./components/elements/privateroute";
import "./sass/styles.scss";
import {AppLayout} from "./components/layouts/app";


const mapStateToProps = (state: IAppState) => {
  return {
    auth: state.user.authState === UserAuthState.AUTH
  };
};

type Props = ReturnType<typeof mapStateToProps>;

const LoggedIn: FunctionComponent = () => <div>You are logged in</div>;

const AppComponent: FunctionComponent<Props> = (props) => {
  return (

        <Switch>
        <Route path="/login" component={LoginPage}/>
        <AppLayout auth={props.auth}/>
        </Switch>

  );
};

export const App = connect(mapStateToProps)(AppComponent);


