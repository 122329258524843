import React, {Component, FormEvent, FunctionComponent, MouseEvent} from 'react';
import "./_index.scss";
import {IAppState, RequestStatus} from "../../../store";
import {Dispatch} from "redux";
import {
    addDatasetActionCreateCsv,
    addDatasetActionHostname,
    addDatasetActionName,
    addDatasetActionPassword,
    addDatasetActionSourcetype,
    addDatasetActionUsername
} from "../../../store/adddataset/actions";
import {DatasetSourceType} from "../../../types";
import {TextBox} from "../textbox";
import {RadioButton} from "../radiobutton";
import {connect} from "react-redux";

type OwnProps = {
    workspace: number
};

const mapStateToProps = (state: IAppState, props: OwnProps) => {
    return {
        name: state.addDataset.name,
        source_type: state.addDataset.source_type,
        hostname: state.addDataset.hostname,
        username: state.addDataset.username,
        password: state.addDataset.password,
        status: state.addDataset.status,
        statusError: state.addDataset.error,
        workspace: props.workspace
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setName: (name: string) => dispatch(addDatasetActionName(name)),
        setSourceType: (type: DatasetSourceType) => dispatch(addDatasetActionSourcetype(type)),
        setHostName: (hostname: string) => dispatch(addDatasetActionHostname(hostname)),
        setUserName: (username: string) => dispatch(addDatasetActionUsername(username)),
        setPassword: (password: string) => dispatch(addDatasetActionPassword(password)),
        createCsvDataset: (workspace: number, name: string, file: File) => dispatch(addDatasetActionCreateCsv(name, file, workspace)),
    };
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

type FileFieldProps = {
    handleChange: (files: FileList | null) => void;
};

const FileField: FunctionComponent<FileFieldProps> = (props) => {
    return (
        <div className="field tim-dataset__fileupload">
            <div className="control">
                <label htmlFor="datasetfileupload">Choose File</label>
                <input type="file" onChange={e => props.handleChange(e.target.files)} id="datasetfileupload"/>
            </div>
        </div>
    );
};

type SqlFormProps = {
    hostname: string,
    setHostname: (hostname:string) => void;
    username: string;
    setUsername: (username: string) => void;
    password: string;
    setPassword: (pass: string) => void;
};

const SqlForm: FunctionComponent<SqlFormProps> = (props) => {
    return (
        <div className="tim-dataset__sqlform">
            <TextBox value={props.hostname} placeholder="Hostname" onChange={e => props.setHostname(e.target.value)}/>
            <TextBox value={props.username} placeholder="Username" onChange={e => props.setUsername(e.target.value)} />
            <TextBox
                type="password"
                value={props.password}
                placeholder="Password"
                onChange={e => props.setPassword(e.target.value)}
            />
        </div>
    );
};

class DatasetformComponent extends Component<Props> {
    protected file: File | null;
    constructor(props) {
        super(props);
        this.file = null;
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    handleFileChange(files: FileList | null) {
        this.file = files ===  null ? null : files[0];
        console.log(this.file);
    }

    handleSubmit(e: MouseEvent) {
        e.preventDefault();
        if(!this.file) {
            return;
        }

        this.props.createCsvDataset(this.props.workspace, this.props.name, this.file);
    }

    render(): React.ReactNode {
        const disabled = this.props.status === RequestStatus.PENDING;

        let mainClass = "tim-workspaceform";
        let buttonText = "Add Dataset";
        if(this.props.status === RequestStatus.SUCCESS) {
            mainClass += " tim-workspaceform__success";
            buttonText = "Dataset Added";
        }

        return(
            <div className={mainClass}>
                <h3>Add Dataset</h3>
                {this.props.statusError && <div className="text-danger">{this.props.statusError}</div>}
                <TextBox
                    value={this.props.name}
                    placeholder="Dataset name"
                    onChange={e => this.props.setName(e.target.value)}
                />
                <div className="tim-workspaceform__radio">
                    <RadioButton
                        label="CSV"
                        id="datasetcsv"
                        name="datasetcsv"
                        value={DatasetSourceType.CSV.toString()}
                        checked={this.props.source_type === DatasetSourceType.CSV}
                        onChange={e => this.props.setSourceType(Number(e.target.value))}
                    />
                    <RadioButton
                        label="SQL"
                        id="datasetsql"
                        name="datasetsql"
                        value={DatasetSourceType.SQL.toString()}
                        checked={this.props.source_type === DatasetSourceType.SQL}
                        onChange={e => this.props.setSourceType(Number(e.target.value))}
                    />
                </div>
                {this.props.source_type === DatasetSourceType.CSV ?
                    <FileField handleChange={this.handleFileChange}/> :
                    <SqlForm
                        hostname={this.props.hostname}
                        setHostname={this.props.setHostName}
                        username={this.props.username}
                        setUsername={this.props.setUserName}
                        password={this.props.password}
                        setPassword={this.props.setPassword}/>
                }

                <div className="field">
                    <div className="control">
                        <button
                            disabled={disabled}
                            onClick={this.handleSubmit.bind(this)}
                            type="submit">{buttonText}</button>
                    </div>
                </div>

            </div>
        );
    }
}

export const DatasetForm = connect(mapStateToProps, mapDispatchToProps)(DatasetformComponent);
