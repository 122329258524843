import React from 'react';
import logo from "../../../assets/navbar-logo.jpg";
import "./_index.scss";

export const Navbar = () => {
    return(
        <div className="tim-navbar">
            <img className="tim-navbar__logo" src={logo}/>
            <div className="tim-navbar__separator"/>
            <div className="tim-navbar__avatar"><span className="tim-icon"></span></div>
            <div className="tim-navbar__avatar">JD</div>
        </div>
    );
};