import React, {ChangeEvent, Component, FormEvent, FunctionComponent, useState} from 'react';
import "./_index.scss";
import {TextBox} from "../textbox";
import {Checkbox} from "../checkbox";

export type LoginFormSubmitData = {
    username: string,
    password: string,
    remember: boolean,
};

type LoginFormProps = {
    inRequest: boolean,
    onSubmit: (data: LoginFormSubmitData) => void,
    errMessage?: string,
};

export const LoginForm: FunctionComponent<LoginFormProps> = (props: LoginFormProps) => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(false);

    // e: ChangeEvent<HTMLInputElement>
    const formSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit({username, password, remember});
    };

    let errMessage;
    if(props.errMessage) {
        errMessage = (
            <div className="text-danger">{props.errMessage || "Email or password incorrect"}</div>
        );
    }


    return(
        <form className="tim-loginform" onSubmit={formSubmit}>
            {errMessage}

            <TextBox
                name="username"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Username or email"
            />
            <TextBox
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
            />

            <div className="field">
                <div className="control tim-loginform__checkboxes">
                    <Checkbox
                        name="remember"
                        onChange={(e) => setRemember(e.target.checked)}
                        text="Remember me"
                        checked={remember}
                    />
                </div>
            </div>

            <div className="field">
                <div className="control">
                    <button disabled={props.inRequest} type="submit">Login</button>
                </div>
            </div>

        </form>
    );
};