import {IWorkspace} from "../../types";

export enum WorkspacesActionTypes {
    LOAD_WORKSPACES = "LOAD_WORKSPACES",
    LOAD_WORKSPACES_SUCCESS = "LOAD_WORKSPACES_SUCCESS",
    LOAD_WORKSPACES_PENDING = "LOAD_WORKSPACES_PENDING",
    LOAD_WORKSPACES_ERROR = "LOAD_WORKSPACES_ERROR",
    UPDATE_WORKSPACES_SEARCH = "UPDATE_WORKSPACES_SEARCH",
    CREATE_WORKSPACE = "CREATE_WORKSPACE",
    SET_CREATE_ERROR = "SET_CREATE_ERROR",
    ADD_WORKSPACE = "ADD_WORKSPACE",
}

export type loadWorkspacesShape = {
    type: WorkspacesActionTypes.LOAD_WORKSPACES
}

export const loadWorkspaces = () => {
    return {
        type: WorkspacesActionTypes.LOAD_WORKSPACES
    }
};


export type loadWorkspacesPendingShape = {
    type: WorkspacesActionTypes.LOAD_WORKSPACES_PENDING
}

export const loadWorkspacesPending = () => {
    return {
        type: WorkspacesActionTypes.LOAD_WORKSPACES_PENDING
    }
};

export type loadWorkspacesSuccessShape = {
    type: WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS,
    payload: IWorkspace[]
}

export const loadWorkspacesSuccess = (workspaces: IWorkspace[]) => {
    return {
        type: WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS,
        payload: workspaces
    }
};

export type loadWorkspacesErrorShape = {
    type: WorkspacesActionTypes.LOAD_WORKSPACES_ERROR,
    payload: string
}

export const loadWorkspacesError = (err: string) => {
    return {
        type: WorkspacesActionTypes.LOAD_WORKSPACES_ERROR,
        payload: err
    }
};

export type updateWorkspaceSearchShape = {
    type: WorkspacesActionTypes.UPDATE_WORKSPACES_SEARCH,
    payload: string
}

export const updateWorkspaceSearch = (query: string) => {
    return {
        type: WorkspacesActionTypes.UPDATE_WORKSPACES_SEARCH,
        payload: query
    }
};

export type createWorkspaceShape = {
    type: WorkspacesActionTypes.CREATE_WORKSPACE,
    payload: {
        name: string,
        ws_type: number
    }
}

export const createWorkspace = (name: string, ws_type: number) => {
    return {
        type: WorkspacesActionTypes.CREATE_WORKSPACE,
        payload: {
            name,
            ws_type
        }
    }
};

export type setCreateWorkspaceErrorShape = {
    type: WorkspacesActionTypes.SET_CREATE_ERROR,
    payload: string
};

export const setCreateWorkspaceError = (err: string) => {
    return {
        type: WorkspacesActionTypes.SET_CREATE_ERROR,
        payload: err
    }
};

export type addWorkspaceActionShape = {
    type: WorkspacesActionTypes.ADD_WORKSPACE,
    payload: IWorkspace
}

export const addWorkspaceAction = (workspace: IWorkspace) => {
    return {
        type: WorkspacesActionTypes.ADD_WORKSPACE,
        payload: workspace
    }
};

export type WorkspacesActionShapes = loadWorkspacesShape |
    loadWorkspacesPendingShape |
    loadWorkspacesSuccessShape |
    updateWorkspaceSearchShape |
    loadWorkspacesErrorShape |
    setCreateWorkspaceErrorShape |
    createWorkspaceShape |
    addWorkspaceActionShape;

