import React, {Component, FunctionComponent} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {Navbar} from "../../elements/navbar";
import {Sidebar, SidebarItem} from "../../elements/sidebar";
import {Workspaces} from "../../pages/workspaces";
import {Workspace} from "../../pages/workspace";
import "./_index.scss";

type Props = {
    auth: boolean
};

export const AppLayout: FunctionComponent<Props> = (props) => {
    if(!props.auth) {
        return <Redirect to="/login"/>
    }

    return (
        <div className="tim-applayout">
            <Navbar/>
            <div className="tim-applayout__body">
                <Sidebar>
                    <SidebarItem icon={"workspaces"} text={"Workspaces"} active={true}/>
                    <SidebarItem icon={"datasets"} text={"Datasets"}/>
                    <SidebarItem icon={"models"} text={"Model building definition"}/>
                    <SidebarItem icon={"forecasting"} text={"Forecasting"}/>
                    <SidebarItem icon={"experiments"} text={"Experiments"}/>
                </Sidebar>
                <div className="tim-applayout__content">
                    <Switch>
                        <Route path="/" component={Workspaces} exact />
                        <Route path="/workspaces/:id" component={Workspace} exact />
                    </Switch>
                </div>
            </div>
        </div>
    )
};