import React, {Component, FunctionComponent} from 'react';
import "./_index.scss";

const data = [
    {
        type: "Dataset",
        name: "Name 1",
        date: "19/4/19",
    },
    {
        type: "Forecasting",
        name: "Name 2",
        date: "19/4/19",
    },
    {
        type: "Dataset",
        name: "Name 3",
        date: "19/4/19",
    },
    {
        type: "Model Building Definition",
        name: "Name 4",
        date: "20/4/19",
    },
    {
        type: "Forecasting",
        name: "Name 5",
        date: "20/4/19",
    },
    {
        type: "Dataset",
        name: "Name 6",
        date: "20/4/19",
    },

];

export const Overview: FunctionComponent = () => {
    return (
        <div className="tim-workspacelist tim-workspacelist__overview">
            <h3>Overview</h3>
            <div className="tim-workspacelist-items">
                {data.map((e, i) =>{
                    return(
                        <div key={i} className="tim-workspacelist-item">
                            <div className="tim-workspacelist-item__name">
                                <div className="tim-workspacelist-item__type">
                                    {e.type}
                                </div>
                                <div className="tim-workspacelist-item__title">
                                    {e.name}
                                </div>
                            </div>
                            <div className="tim-workspacelist-item__date">{e.date}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};