import {DatasetSourceType} from "../../types";

export enum AddDatasetActionTypes {
    SET_NAME = "SET_NAME",
    SET_SOURCE_TYPE = "SET_SOURCE_TYPE",
    SET_HOSTNAME = "SET_HOSTNAME",
    SET_USERNAME = "SET_USERNAME",
    SET_PASSWORD = "SET_PASSWORD",
    SET_PENDING_STATUS = "SET_PENDING_STATUS",
    SET_SUCCESS_STATUS = "SET_SUCCESS_STATUS",
    SET_ERROR_STATUS = "SET_ERROR_STATUS",
    RESET_STATE = "RESET_STATE",
    CREATE_DATASET_CSV = "CREATE_DATASET_CSV",
}

export type AddDatasetActionShape = {
    type: AddDatasetActionTypes,
    payload: string,
};

export const addDatasetActionName = (name: string) => {
    return {
        type: AddDatasetActionTypes.SET_NAME,
        payload: name
    };
};

export type addDatasetActionSourcetypeShape = {
    type: AddDatasetActionTypes.SET_SOURCE_TYPE,
    payload: DatasetSourceType
}

export const addDatasetActionSourcetype = (source_type: DatasetSourceType) => {
    return {
        type: AddDatasetActionTypes.SET_SOURCE_TYPE,
        payload: source_type
    }
};

export const addDatasetActionHostname = (hostname: string) => {
    return {
        type: AddDatasetActionTypes.SET_HOSTNAME,
        payload: hostname
    };
};

export const addDatasetActionUsername = (username: string) => {
    return {
        type: AddDatasetActionTypes.SET_USERNAME,
        payload: username
    };
};

export const addDatasetActionPassword = (password: string) => {
    return {
        type: AddDatasetActionTypes.SET_PASSWORD,
        payload: password
    };
};

export const addDatasetActionError = (err: string) => {
    return {
        type: AddDatasetActionTypes.SET_ERROR_STATUS,
        payload: err
    };
};

export type addDatasetActionCreateCsvShape = {
    type: AddDatasetActionTypes.CREATE_DATASET_CSV,
    payload: {
        name: string,
        file: File,
        workspace: number,
    }

};

export const addDatasetActionCreateCsv = (name: string, file: File, workspace: number) => {
    return {
        type: AddDatasetActionTypes.CREATE_DATASET_CSV,
        payload: {
            name,
            file,
            workspace
        }
    };
};


export type AddDatasetActionShapes = AddDatasetActionShape |
    addDatasetActionSourcetypeShape |
    addDatasetActionCreateCsvShape;