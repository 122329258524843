import React, {Component, FormEvent, FunctionComponent, useState} from 'react';
import "./_index.scss";
import {RadioButton} from "../radiobutton";
import {TextBox} from "../textbox";

type Props = {
    create: (name: string, ws_type: number) => void
};

export const WorkspaceForm: FunctionComponent<Props> = (props) => {
    const [name, setName] = useState("");
    const [ws_type, setType] = useState(0);
    console.log(name, ws_type);
    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.create(name, ws_type);
        setName("");
        setType(0);
    };

    const handleChange = (val: string) => {
        setType(Number(val));
    };

    return (
        <form className="tim-workspaceform" onSubmit={onSubmit}>
            <h3>Add Workspace</h3>
            <TextBox placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
            <div className="tim-workspaceform__radio">
                <RadioButton
                    label="Forecasting"
                    id="test1"
                    name="workspacetype"
                    value="0"
                    checked={ws_type === 0}
                    onChange={(e) => handleChange(e.target.value)}/>
                <RadioButton
                    label="Anomaly"
                    id="test2"
                    name="workspacetype"
                    value="1"
                    checked={ws_type === 1}
                    onChange={(e) => handleChange(e.target.value)}
                />
            </div>

            <div className="field">
                <div className="control">
                    <button type="submit">Add Workspace</button>
                </div>
            </div>
        </form>
    );
};