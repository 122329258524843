import React, {Component, FunctionComponent} from 'react';
import {IAppState} from "../../../store";
import {Dispatch} from "redux";
import {createWorkspace, loadWorkspaces, updateWorkspaceSearch} from "../../../store/workspaces/actions";
import {connect} from "react-redux";
import {WorkspaceList} from "../../elements/workspacelist";
import "./_index.scss";
import {TextBox} from "../../elements/textbox";
import {WorkspaceForm} from "../../elements/workspaceform";
import {Overview} from "./overview";

const mapStateToProps = (state: IAppState) => {
    return {
        loadStatus: state.workspaces.loadStatus,
        workspaces: state.workspaces.workspaces,
        loadError: state.workspaces.loadError,
        search: state.workspaces.search,
        createError: state.workspaces.createError,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        loadWorkspaces: () => dispatch(loadWorkspaces()),
        updateSearch: (query: string) => dispatch(updateWorkspaceSearch(query)),
        create: (name: string, ws_type: number) => dispatch(createWorkspace(name, ws_type)),

    }
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const WorkspacesComponent: FunctionComponent<Props> = (props) => {
    if(props.loadStatus === null) {
        props.loadWorkspaces();
        return <div>Loading...</div>
    }

    let items = props.workspaces;
    if(props.search) {
        items = items.filter(e => e.name.toLocaleLowerCase().includes(props.search.toLocaleLowerCase()));
    }

    return (
        <div className="container tim-workspaces">
            {props.createError && <h3 className="text-danger">{props.createError}</h3>}
            <div className="tim-workspaces__addform">
                <WorkspaceForm create={props.create}/>
            </div>
            <div className="tim-workspaces__searchbox">
                <label>Search</label>
                <div className="tim-workspaces__searchwrapper">
                    <TextBox
                        value={props.search}
                        wrapped={false}
                        onChange={e => props.updateSearch(e.target.value)}
                    />
                    <span className="tim-icon"></span>
                </div>
            </div>
            <div className="columns is-desktop">
                <div className="column is-4">
                    <WorkspaceList
                        items={items.filter(e => e.ws_type === 0)}
                        title="Forecasting"
                    />
                </div>
                <div className="column is-4">
                    <WorkspaceList
                        items={items.filter(e => e.ws_type === 1)}
                        title="Anomaly"
                    />
                </div>
                <div className="column is-4">
                    <Overview/>
                </div>
            </div>
        </div>
    );
};

export const Workspaces = connect(mapStateToProps, mapDispatchToProps)(WorkspacesComponent);