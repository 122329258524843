import {IUser} from "../../types";

export enum UserActionTypes {
    LOGIN = "LOGIN",
    LOGIN_PENDING = "LOGIN_PENDING",
    SET_USER_DATA = "SET_USER_DATA",
    LOGIN_ERROR = "LOGIN_ERROR",
    LOGOUT = "LOGOUT",
    REFRESH_USER = "REFRESH_USER",
}

export type UserLoginActionShape = {
    type: UserActionTypes.LOGIN,
    payload: {
        email: string,
        password: string,
        remember: boolean
    }
}

export const userLoginAction = (
    email: string,
    password: string,
    remember: boolean): UserLoginActionShape => {

    return {
        type: UserActionTypes.LOGIN,
        payload: {
            email,
            password,
            remember
        }
    };
};

export type userLoginPendingShape = {
    type: UserActionTypes.LOGIN_PENDING
};

export const userLoginPending = () => {
    return {
    type: UserActionTypes.LOGIN_PENDING
    };
};

export type setUserActionShape = {
    type: UserActionTypes.SET_USER_DATA,
    payload: IUser
};

export const setUserAction = (user: IUser): setUserActionShape => {
    return {
        type: UserActionTypes.SET_USER_DATA,
        payload: user
    }
};

export type loginErrorActionShape = {
    type: UserActionTypes.LOGIN_ERROR,
    payload: string
}

export const loginErrorAction = (err: string): loginErrorActionShape => {
    return {
        type: UserActionTypes.LOGIN_ERROR,
        payload: err
    }
};

export type logoutActionShape = {
    type: UserActionTypes.LOGOUT
};

export const logoutAction = () => {
    return {
        type: UserActionTypes.LOGOUT
    }
};

export type refreshUserActionShape = {
    type: UserActionTypes.REFRESH_USER,
    payload: number
}

export const refreshUserAction = (id: number) => {
    return {
        type: UserActionTypes.REFRESH_USER,
        payload: id
    }
};

export type UserActionShapes =
    UserLoginActionShape |
    userLoginPendingShape |
    setUserActionShape |
    loginErrorActionShape |
    logoutActionShape |
    refreshUserActionShape;


