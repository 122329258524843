import {IAppState, RequestStatus} from "../index";
import {IDataset} from "../../types";
import {datasetActions, DatasetActionShapes, DatasetActionTypes} from "./actions";
import {ApiClient} from "../../apiclient";
import {Dispatch, Middleware, MiddlewareAPI} from "redux";

export interface IDatasetState {
    id: number | null;
    status: null | RequestStatus;
    dataset: null | IDataset;
    error: string;
}

const defaultState: IDatasetState = {
    id: null,
    status: null,
    dataset: null,
    error: "",
};

export const datasetReducer = (state: IDatasetState = defaultState, acton: DatasetActionShapes): IDatasetState => {

    switch (acton.type) {
        case DatasetActionTypes.PENDING:
            return {
                ...defaultState,
                id: acton.payload,
                status: RequestStatus.PENDING,
            };

        case DatasetActionTypes.SUCCESS:
            return {
                ...state,
                dataset: acton.payload,
                status: RequestStatus.SUCCESS,
                error: "",
            };

        case DatasetActionTypes.ERROR:
            return {
                ...state,
                status: RequestStatus.ERROR,
                error: acton.payload,
            };

        case DatasetActionTypes.CLEAR:
            return {
                ...defaultState
            };
    }

    return state;
};

export const datasetMiddlewareFactiory = (client: ApiClient): Middleware => {
    return (store: MiddlewareAPI<Dispatch, IAppState>) => (next: Dispatch) => (action: DatasetActionShapes) => {
        if(action.type === DatasetActionTypes.LOAD) {
            const id = action.payload;
            store.dispatch(datasetActions.pending(id));
            return client.dataset.get(id).then(dataset => {
                store.dispatch(datasetActions.success(dataset));
            }).catch(err => store.dispatch(datasetActions.fail(err.message)));
        }

        return next(action);
    };
};